<template>
  <CContainer class="min-vh-100" style="background-color: #ffffff;">
    <CRow>
      <CCard class="text-dark col-md-5 mt-4" body-wrapper>
        <h4 class="font-weight-normal text-left">{{ voucher.title }}</h4>
        <p class="text-left text-description">{{ voucher.description }}</p>
        <hr />
        <div class="text-left font-weight-normal text-grey p-1">
          <em class="fa fa-calendar-check-o"></em> วันที่รับ : {{ createdAt }}
        </div>
        <CCard>
          <CCardBody color="light">
            <h5 class="text-dark mt-1 text-center"> Voucher Code : {{ data.voucherCode }}</h5>
            <p class="text-center">
              <vue-qrcode
                class="img-fluid"
                :value="qrcodeData"
                style="width:300px"
              />
            </p>
            <h6 class="text-center text-grey" style="font-size:12px">
              หมดอายุ {{ expiredAt }}
            </h6>
          </CCardBody>
        </CCard>
        <hr />
        {{ checkVoucherStatus(isUsed) }}
        <div v-if="usebutton === 'enable'">
          <p class="text-center text-grey">
            กรุณาแสดง E-Voucher นี้ให้แก่ร้านค้าเพื่อใช้สิทธิ์
          </p>

          <CButton class="text-white" color="success" @click="useVoucher" block size="lg">
            ใช้ Voucher
          </CButton>
        </div>
        <div v-else-if="usebutton === 'disabled'">
          <CButton color="danger" block disabled size="lg">
            {{ buttontext }}
          </CButton>
        </div>
      </CCard>
    </CRow>
  </CContainer>
</template>

<script>
import crm from '@/services/crm'
import liff from '@line/liff'
import moment from 'moment'
import VueQrcode from 'vue-qrcode'

export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      data: {},
      member: {},
      uid: '',
      isUsed: '',
      voucher: '',
      title: '',
      yourpoint: 0,
      imgUrl: '',
      description: '',
      qrcodeData: '',
      voucherCode: '',
      usebutton: '',
      buttontext: '',
    }
  },
  computed: {
    objectId() {
      return this.$route.params.objectId
    },
    createdAt() {
      return moment(this.data.created_at).format('LLL')
    },
    expiredAt() {
      return moment(this.data.endAt).format('LLL')
    },
  },
  created() {
    this.getRedemption()
  },
  methods: {
    checkVoucherStatus(isUsed) {
      let datetime = new Date()
      let expiration = moment(this.data.endAt).isBefore(datetime)

      if (isUsed === false || isUsed === undefined) {
        if (expiration) {
          this.usebutton = 'disabled'
          this.buttontext = 'บัตรกำนัลหมดอายุแล้ว'
        } else {
          this.usebutton = 'enable'
        }
      } else if (isUsed === true) {
        this.usebutton = 'disabled'
        this.buttontext = 'บัตรกำนัลถูกใช้งานแล้ว'
      }
    },
    getRedemption() {
      let objectId = this.objectId

      crm
        .get('/api/v1.0/voucher/getredemptionbyobjectId/' + objectId)
        .then((res) => {
          if (res.data.error.code === 0) {
            this.data = res.data.data.documents
            this.voucherCode = this.data.voucherCode
            this.voucher = this.data.Voucher

            this.imgUrl = this.voucher.imgUrl
            this.title = this.voucher.title
            this.isUsed = this.data.isUsed
            this.qrcodeData =
              this.objectId + '|' + this.voucherCode + '|' + this.voucher.value

            document.title = this.title
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    useVoucher() {
      let objectId = this.objectId

      crm
        .post('/api/v1.0/voucher/use/' + objectId)
        .then((res) => {
          alert('บัตรกำนัลถูกใช้งาน')
          liff.closeWindow()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
